export const booleanAttributes: Record<string, Record<string, string[]>> = {
    allowfullscreen: {},
    async: {},
    autofocus: {},
    autoplay: {},
    checked: {},
    controls: {},
    default: {},
    defer: {},
    disabled: {},
    formnovalidate: {},
    hidden: {
        possibleValues: ['until-found', 'hidden'],
    },
    ismap: {},
    itemscope: {},
    loop: {},
    multiple: {},
    muted: {},
    nomodule: {},
    novalidate: {},
    open: {},
    playsinline: {},
    readonly: {},
    required: {},
    reversed: {},
    selected: {},
}
